export const LOADER_STATUS = {
  loading: "loading",
  idle: "idle",
  global: "globalLoading",
  error: "error",
  success: "success",
};

export const TABLES = {
  theme: "theme",
  question: "question",
};

export const PAGINATION_SORT = {
  fromSmallest: "desc",
  fromLargest: "asc",
};

export const QUESTION_TITLES = [
  { name: "check", value: undefined },
  { name: "id", value: "_id" },
  { name: "Тема вопроса", value: "theme_id" },
  { name: "Текст вопроса", value: "text" },
  { name: "Отображение", value: "is_hidden" },
  { name: "Дата обновления", value: "updated_at" },
  { name: "Дата создания", value: "created_at" },
  { name: "Действия", value: undefined },
];

export const THEME_TITLES = [
  { name: "id", value: "_id" },
  { name: "Название темы", value: "name" },
  { name: "Вопросы", value: "questionCount" },
  { name: "Отображение", value: "is_hidden" },
  { name: "Дата обновления", value: "updated_at" },
  { name: "Дата создания", value: "created_at" },
  { name: "Действия", value: undefined },
];

export const ACTIONS_MODE = {
  edit: "edit",
  create: "create",
  delete: "delete",
};

export const THEME_SELECTOR_MODE = {
  filter: "filter",
  value: "value",
};

export const FILE_MESSAGES = {
  theme_text: "В следующих строках ошибка в тексте новой темы",
  theme_id_not_found: "В следующих строках не найден id тем",
  question_text: "В следующих строках ошибка в тексте вопроса",
  question_comment: "В следующих строках ошибка в тексте комментария к вопросу",
  question_answer: "В следующих строках ошибка в тексте ответа",
  question_duplicate: "В следующих строках дубликаты вопросов одной темы",
  question_answer_duplicate:
    "В следующих строках дубликаты ответов одного вопроса",
  question_exists:
    "В следующих строках ошибка связанная с найденными в базе вопросами одной темы",
};

export const ERROR_MESSAGES = {
  [-1]: "Что то пошло не так, повторите снова",
  1: "Неверные параметры",
  2: "Неверный пароль или email",
  3: "Не найдено",
  4: "JWT отсутствует",
  5: "Неверный JWT",
  6: "Истек срок действия JWT",
  7: "Тема уже существует",
  8: "Вопрос уже существует",
  9: "Тема не найдена",
  10: "Тема не найдена",
  11: "Импортируемый файл пуст",
  12: "Произошла ошибка при открытии импортируемого файла",
  13: "Неверный формат импортируемого файла",
  14: "Превышен лимит строк в импортируемом файле",
  15: "Отсутствуют вопросы в импортируемом файле",
  16: "Неверный текст",
  17: "Неверный комментарий",
  18: "Неверный вопрос",
  19: "Данный вопрос уже существует",
};

export const RESULTS_PER_PAGE = 100;
export const THEME_FILTER_RESULTS_LENGTH = 10;
export const DEFAULT_ORDER_THEME = "_id";
export const DEFAULT_ORDER_QUESTION = "_id";
export const MAX_THEME_NAME_LENGTH = 15;
export const MAX_QUESTION_LENGTH = 300;
export const MAX_QUESTION_COMMENT_LENGTH = 300;
export const MAX_ANSWER_LENGTH = 100;
