import Http from "./http";
import { ERROR_MESSAGES } from "../utils/constants";

export const getThemesItems = async (
  page,
  dataPerPage,
  sortBy,
  orderBy,
  filter
) => {
  try {
    const response = await Http.Private().get("/api/themes", {
      params: {
        page,
        dataPerPage,
        sortBy,
        orderBy,
        filter,
      },
    });
    if (response.status === 200) {
      if (!response.data.response.themes) {
        return { themes: [], totalItems: 0 };
      }
      return response.data.response;
    }
  } catch (error) {
    return { themes: [], totalItems: 0 };
  }
};

export const sendNewThemeItem = async (item) => {
  try {
    const response = await Http.Private().put("/api/themes", {
      id: item.id,
      name: item.name,
      isHidden: item.isHidden,
    });

    if (response.data.error !== null) {
      let error = ERROR_MESSAGES[response.data.error.code];

      return { status: false, message: error };
    }

    if (response.data.response) return { status: true, message: "" };

    throw new Error("400");
  } catch (error) {
    return { status: false, message: ERROR_MESSAGES[-1] };
  }
};

export const createNewThemeItem = async (item) => {
  try {
    const response = await Http.Private().post("/api/themes", {
      name: item.name,
      isHidden: item.isHidden,
    });

    if (response.data.error !== null) {
      let error = ERROR_MESSAGES[response.data.error.code];

      return { status: false, message: error };
    }

    if (response.data.response) return { status: true, message: "" };

    throw new Error("400");
  } catch (error) {
    return { status: false, message: ERROR_MESSAGES[-1] };
  }
};

export const deleteTheme = async (item) => {
  try {
    const response = await Http.Private().delete("/api/themes", {
      data: {
        id: item.id,
      },
    });

    if (response.data.error !== null) {
      let error = ERROR_MESSAGES[response.data.error.code];

      return { status: false, message: error };
    }

    if (response.data.response) return { status: true, message: "" };

    throw new Error("400");
  } catch (error) {
    return { status: false, message: ERROR_MESSAGES[-1] };
  }
};
