import React, { Suspense } from "react";
import { Windmill } from "@windmill/react-ui";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import { SidebarProvider } from "./context/SidebarContext";
import GlobalLoader from "./components/GlobalLoader";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import App from "./App";
import windmillTheme from "./windmillTheme.js";
import "./assets/css/tailwind.output.css";

// if (process.env.NODE_ENV !== 'production') {
//   const axe = require('react-axe')
//   axe(React, ReactDOM, 1000)
// }

ReactDOM.render(
  <Provider store={store}>
    <SidebarProvider>
      <Suspense fallback={<GlobalLoader vision />}>
        <Windmill usePreferences theme={windmillTheme}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Windmill>
      </Suspense>
    </SidebarProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
