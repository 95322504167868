import Http from "./http";
import { ERROR_MESSAGES } from "../utils/constants";

export const getQuestionItems = async (
  page,
  resultsPerPage,
  sortBy,
  orderBy,
  filter,
  from,
  to
) => {
  try {
    const response = await Http.Private().post("/api/getQuestions", {
      page,
      dataPerPage: resultsPerPage,
      sortBy,
      orderBy,
      theme_id: filter.map((el) => el.id),
      time_interval: {
        from: ~~(new Date(from).getTime() / 1000),
        to: ~~(new Date(to).getTime() / 1000),
      },
    });

    if (response.status === 200) {
      if (!response.data.response.questions) {
        return { questions: [], totalItems: 0 };
      }

      return {
        ...response.data.response,
        questions: response.data.response.questions.map((el) => {
          const { theme_id, theme, ...etc } = el;
          return {
            ...etc,
            theme: { id: theme_id, name: theme },
          };
        }),
      };
    }
    throw new Error("404");
  } catch (error) {
    return { questions: [], totalItems: 0 };
  }
};

export const sendNewQuestionItem = async (item) => {
  // TODO: remove newItem
  const { theme, ...etc } = item;
  const newItem = { ...etc, theme_id: item.theme.id };

  try {
    const response = await Http.Private().put("/api/questions", newItem);

    if (response.data.error !== null) {
      let error = ERROR_MESSAGES[response.data.error.code];

      return { status: false, message: error };
    }

    if (response.data.response) return { status: true, message: "" };

    throw new Error("404");
  } catch (error) {
    return { status: false, message: ERROR_MESSAGES[-1] };
  }
};

export const createNewQuestionItem = async (item) => {
  const { theme, ...etc } = item;
  const newItem = { ...etc, theme_id: item.theme.id };

  try {
    const response = await Http.Private().post("/api/questions", newItem);

    if (response.data.error !== null) {
      let error = ERROR_MESSAGES[response.data.error.code];

      return { status: false, message: error };
    }

    if (response.data.response) return { status: true, message: "" };

    throw new Error("404");
  } catch (error) {
    return { status: false, message: ERROR_MESSAGES[-1] };
  }
};

export const deleteQuestion = async (item) => {
  try {
    const response = await Http.Private().delete("/api/questions", {
      data: {
        id: item,
      },
    });

    if (response.data.error !== null) {
      let error = ERROR_MESSAGES[response.data.error.code];

      return { status: false, message: error };
    }

    if (response.data.response) return { status: true, message: "" };

    throw new Error("404");
  } catch (error) {
    return { status: false, message: ERROR_MESSAGES[-1] };
  }
};
