import axios from 'axios';

const CURRENT_URL = `${process.env.REACT_APP_BACK_URL}`;

const Http = {
  Public: () => axios.create({
    baseURL: CURRENT_URL,
    timeout: 7000
  }),
  Private: () => axios.create({
    baseURL: CURRENT_URL,
    timeout: 7000,
    headers: {
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
  })
};

export default Http;